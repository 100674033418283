$red: #dc3545;
$blue: #007bff;
$danger: $red;
$primary: $danger;
$component-active-bg: $primary;
$font-family-sans-serif: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$enable-shadows: false;
$scroll-behavior: smooth;


#image-loader {
    text-align: center;
}

#image-loader img {
    display: none;
    height: 60px;
    width: 60px;
}

.grecaptcha-badge {
    visibility: hidden;
}